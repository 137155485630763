<template>
  <div class="result-page">
    <div class="not-vip">
      <img src="@/assets/images/error.png" class="none-image" />
      <template v-if="!userinfo.expired">
        <p class="tip theme em">
          您的会员资格有效时间至： {{ userinfo.expiredate | formatDate }}
        </p>
      </template>
      <template v-else>
        <p v-if="!userinfo.expiredate" class="tip danger em">
          检测到您还不是本站会员，暂不可使用该服务！
        </p>
        <p v-if="userinfo.expiredate" class="tip danger em">
          您的会员已过期，暂不可使用该服务！
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.result-page {
  padding: 30px;
  min-height: 400px;
}
.not-vip {
  padding-top: 30px;
  text-align: center;
  .none-image {
    width: 200px;
  }
  .tip {
    margin-top: 10px;
  }
}
</style>
